import styles from "./post.module.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Sidebar } from "../components/sidebar";
import baseImage from "../images/BaseImage.jpg";

import { PostParent } from "../components/postParent";
import YoutubeEmbed from "../components/youtube";
import { PostImg } from "../components/postImg";

import LostIsland02 from "../images/LostIsland02.jpg";

import LostIsland03 from "../images/LostIsland03.jpg";
import LostIsland01 from "../images/LostIsland01.jpg";

// Content //
function Post03(props) {
  return (
    <div>
      {props.width >= 1000 && (
        <Sidebar>
          <PostParent
            imgSrc={props.imgSrc}
            page={props.page}
            unreal="true"
            speedtree="true"
          ></PostParent>
        </Sidebar>
      )}

      <div className={styles.post}>
        <div className={styles.content} nostyle={{ backgroundColor: "red" }}>
          <div class="row">
            <div class="col-sm-12">
              {" "}
              <div className={styles.hTitle}>
                <h2>
                  {props.page[props.id]
                    .substring(1)
                    .replace("_", " ")
                    .replace("_", " ")}
                </h2>
              </div>
            </div>
            <div class="col-sm-7">
              <div class="row"></div>
            </div>
          </div>
          <br />
          <div className={styles.centerDiv}></div>

          <PostImg
            src={LostIsland01}
          ></PostImg>

          <br></br>

          <p>
            This is a personal project I created to learn Unreal Engine 5, when
            the Early access first released. Originally I just wanted to play
            around with the new tools available, but it quickly turned into a
            whole Island.
          </p>
          <br></br>
      
          <PostImg src={LostIsland02}></PostImg>
          <p></p>

          <p>
            The terrain and foliage is spawned procedurally, which gives me a
            much faster iteration process when shaping the Island. Rivers, and mountains are made by drawing with splines.
             Most of the assets are from the built-in Megascans library, as I wanted to practice terrain generation.
          </p>
          
          <PostImg src={LostIsland03}></PostImg>
        </div>
        <br></br>

        {/* FOOTER */}
   
        <div className={styles.bar}>
          {" "}
          <a href="/"> home </a> - <a href="/about"> contact </a> - © Copyright
          2022 Erlend Brodal - All Rights Reserved{" "}
        </div>
      </div>
    </div>
  );
}

export default Post03;
