import styles from "./post.module.css";

import { Sidebar } from "../components/sidebar";
import { BaseButton } from "../components/baseButton";
import ProgressiveImg from "../components/progressiveImg";
import baseImage from "../images/BaseImage.jpg";
import { Softwares } from "../components/sidebar/softwares";
import { Other } from "../components/sidebar/other";

import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Alert, Breadcrumb, Cart, Card } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/row";
import { Link } from "react-router-dom";
import { PostParent } from "../components/postParent";
import YoutubeEmbed from "../components/youtube";
import { PostImg } from "../components/postImg";

import ROR01 from "../images/ROR01.jpg"
import ROR02 from "../images/ROR02.jpg"
import ROR03 from "../images/ROR03.jpg"

// Content //
function Post01(props) {
  return (
    <div>
      {props.width >= 1000 && (
        <Sidebar>
          <PostParent
            imgSrc={props.imgSrc}
            page={props.page}
            unreal="true"
            maya="true"
            blender="true"
            painter="true"
            designer="true"
         
          ></PostParent>
        </Sidebar>
      )}

      <div className={styles.post} nostyle={{ backgroundColor: "blue" }}>
        <div className={styles.content} nostyle={{ backgroundColor: "red" }}>
          <div class="row">
            <div class="col-sm-6">
              {" "}
              <div className={styles.hTitle}>
                <h2>
                  {props.page[props.id]
                    .substring(1)
                    .replace("_", " ")
                    .replace("_", " ")}
                </h2>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-12">
                  {" "}
                  <a href="https://rulesofrobots.com/">
                    <div className={styles.hButton}>
                      <BaseButton title="RoR website" /> 
                    </div>
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>

          <div style={{ padding: "5px" }}></div>

          <div className={styles.centerDiv}>
            {" "}
            <YoutubeEmbed
              embedId="i_1fVY4m4Wo"
              width="300px"
              height="200px"
            ></YoutubeEmbed>{" "}
          </div>
          <p></p>

        
          <p>
            This game is currently under development by{" "}
            <a href="https://sharpraccoon.com/">Sharp Raccoon</a>, an indie
            company that I created with two others and have been working
            full-time for since 2021. Rules of Robots lets players create their
            own maps, and rules in the game, share them online, and play with
            friends.
          </p>
          <p>
            My <b>main contribution</b> to the project alongside building the
            company have been, UI, Animation, Shaders, Gameplay and Game Design.
          </p>

          <br></br>

          <h4>UI</h4>

          <p>
            The UI is using a custom component setup I created to handle both
            keyboard and controller input simultaneously. By using components I
            could develop fast while reusing functions and having a simple way
            to change the art at any point without having to remake any UI
            elements.
            {/* Add image of UI component settings */}
          </p>
          <PostImg src={ROR01}></PostImg>

          <p>
            <br></br>I also created a developer tool using the Editor Utility
            Widget. This lets me render a custom panel inside the Unreal editor
            where I can run my own functions. The tool has some neat settings
            that allow us to work faster, by eliminating repetitive tasks and
            creating shortcuts, such as loading levels or pack textures for
            optimization. These tools grow as the project grows since I try to
            only implement tools that are necessary and will save more time than
            it takes to make them.
          </p>

          <br />
          <h4>Level Editor</h4>
          <br />

          <PostImg src={ROR02}></PostImg>
          <p className="imgText">
            !Art in the game is placeholder as we focus on good gameplay first
          </p>
          <p>
            We decided to add a level editor to the game early in the
            development phase, after discovering that players enjoy having
            variety in the game after early playtests. Building blocks such as
            teleporters, boost pads and booby traps let the player create their
            own twist of the game for increased replayability. There are around
            20 items to use at the moment, with systems to easily add more.
          </p>
          <PostImg src={ROR03}></PostImg>
          <br></br>
          <p>
            {/**/}
            The possibility to quickly test out new items as well as rules is an
            import part of our development process, so we try to make everything
            as modular as possible. For the level editor, weapons, and other
            systems, we take advantage of data tables. When we add a new item we
            simply need to fill in some simple values, and the system will
            automatically integrate the changes to the game, such as adding a new
            UI automatically or add a new weapon for players to use. These
            tables are also used to drive UI elements in the game such as displaying ammo
            or decide how an animation should play.
          </p>

          {/*
          <div class="row" style={{ padding: "5px", margin: "-5px" }}>

            <div class="col-md-6 p-1">
              <PostImg src={"https://rulesofrobots.com/assets/images/nbce2o.gif"}></PostImg>
              <p className="imgText">hello text</p>
            </div>

            <div class="col-md-6 p-1">

            <PostImg src={"https://erlendbrodal.com/assets/images/ror-ingamescreenshot-2-1773x997.jpg"}></PostImg>
              <p className="imgText">hello text</p>
            </div>
          </div>
        </div>
        <br></br>

        <div className={styles.bar}>
          {" "}
          <a href="/"> home </a> - <a href="/about"> contact </a> - © Copyright
          2022 Erlend Brodal - All Rights Reserved{" "}*/}
        </div>
        <br></br>{" "}
        <div className={styles.bar}>
          {" "}
          <a href="/"> home </a> - <a href="/about"> contact </a> - © Copyright
          2022 Erlend Brodal - All Rights Reserved{" "}
        </div>
      </div>
    </div>
  );
}

export default Post01;
