import React from "react";
import styles from "./base.module.css";

import { Link } from "react-router-dom";
import { BaseButton } from "./baseButton";
import { Softwares } from "./sidebar/softwares";
import { Other } from "./sidebar/other";
import ProgressiveImg from "./progressiveImg";

import baseImage from "../images/BaseImage.jpg";

export const PostParent = (props) => {
    return (
        <div>
            <div style={{ padding: "10px" }} >
          
              <div class="row" style={{ padding: "0px", margin: "0px" }}>
                <div class="col-md-6" > <Link to="/"><BaseButton imgUrl="https://i.ibb.co/3Mm3WHb/Return.png" title="return" ></BaseButton> </Link></div>
                <div class="col-md-6"> <Link to="/about"><BaseButton outline="yes" title="contact" ></BaseButton> </Link></div>
              </div>
            
          
              <hr></hr>
            <Softwares unreal={props.unreal} blender={props.blender} unity={props.unity} maya={props.maya} designer={props.designer} painter={props.painter}  worldmachine={props.worldmachine} speedtree={props.speedtree}></Softwares>
            </div>
       

        
          <hr></hr>
            <Other imgSrc={props.imgSrc} page={props.page} curPage={props.curPage} changeDisplay={props.changeDisplay}></Other>
          </div>
    );
  };