import React from "react";
import { BaseButton } from "../components/baseButton";
import styles from "./base.module.css";
import { Link } from "react-router-dom";

export const AboutSidebar = (props) => {
  return (
    <div className={styles.aboutBorder}>
    
      <h1  className={styles.aboutName}>Erlend Brodal {window.name}</h1>
      <div><h2  className={styles.aboutTitle}>Game Developer</h2></div>
      <p></p>

      <p  className={styles.aboutText}>Hi! I'm a <b>Technical Artist</b> with a passion for proceduralism and non-destructive workflows</p>

   <br></br>
  
      <Link to="about"><BaseButton title="About/Contact"
        onClick={() => {
          props.changeDisplay("aboutPage");
        }}
      ></BaseButton></Link>
    </div>
  );
};
