import React from 'react';
import { Link, Navigate } from 'react-router-dom';

const NotFound = () => (
  <div>
    <h1>404 - Navigating back to menu</h1>
    <Navigate replace to="/" />
  </div>
);

export default NotFound;