import styles from "./post.module.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Sidebar } from "../components/sidebar";
import baseImage from "../images/BaseImage.jpg";

import { PostParent } from "../components/postParent";
import YoutubeEmbed from "../components/youtube";
import { PostImg } from "../components/postImg";


import LostIsland02 from "../images/LostIsland02.jpg";
import SecondRing01 from "../images/SecondRing01.gif";
import SecondRing02_SBS from "../images/SecondRing02_SBS.gif";
import SecondRing03 from "../images/SecondRing03.gif";
import SecondRing04 from "../images/SecondRing04.gif";
import SecondRing05 from "../images/SecondRing05.gif";
import SecondRing06 from "../images/SecondRing06.jpg";
// Content //
function Post04(props) {
  return (
    <div>
      {props.width >= 1000 && (
        <Sidebar>
          <PostParent
            imgSrc={props.imgSrc}
            page={props.page}
            unreal="false"
            unity="true"
            maya="true"
          
            designer="true"

          ></PostParent>
        </Sidebar>
      )}

      <div className={styles.post}>
        <div className={styles.content} nostyle={{ backgroundColor: "red" }}>
       
          <div class="row">
            <div class="col-sm-12">
              {" "}
              <div className={styles.hTitle}>
                <h2>
                  {props.page[props.id]
                    .substring(1)
                    .replace("_", " ")
                    .replace("_", " ")}
                </h2>
              </div>
            </div>
            <div class="col-sm-7">
              <div class="row"></div>
            </div>
          </div>
          <br />
          <div className={styles.centerDiv}>
        
          </div>

          
          <PostImg src={SecondRing01}></PostImg>
          <br></br>
          <p>
          My task was to create all the visuals in this narrative walking simulator.
           These tasks included generating terrain, foliage, shaders, 3D modelling and texturing, as well as lighting. This was a short project as part of a course in Level Design.
          </p>

         
          <div class="row" style={{ padding: "5px", margin: "-5px" }}>
            <div class="col-md-5 p-1">
              {" "}
              <PostImg width ="100%" src={SecondRing02_SBS}></PostImg>
              <p className="imgText">All textures are procedural</p>
            </div>
            <div class="col-md-7 p-1">
              {" "}
              <PostImg width ="100%" src={SecondRing06}></PostImg>
              
            </div>
          </div>

          <br>
          </br>

      

          <PostImg src={SecondRing03}></PostImg>
          <p className="imgText"> The two rings surounding the planet was made proceduraly in Substance Designer</p>


          
          <div class="row" style={{ padding: "5px", margin: "-5px" }}>
            <div class="col-md-6 p-1">
              {" "}
              <PostImg width ="100%" src={SecondRing04}></PostImg>
              <p className="imgText">Cool plant made with vertex shaders</p>
            </div>
            <div class="col-md-6 p-1">
              {" "}
              <PostImg imgWidth="350px" width ="100%" src={SecondRing05}></PostImg>
              <p className="imgText" style={{ textAlign: "left"}}>Cliff shader. The grass adjust based on mesh rotation, and the colors and wear can be adjusted for more variation</p>
              
            </div>
          </div>


       




        

       
          <br></br>
       
         
        </div>
        <br></br>
        <div className={styles.bar}> <a href="/"> home </a> - <a href="/about"> contact </a> - © Copyright 2022 Erlend Brodal - All Rights Reserved      </div>
      </div>
    </div>
  );
}

export default Post04;