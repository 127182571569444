import React, { useState, useEffect } from "react";
import styles from "../components/base.module.css";

import { BaseButton } from "../components/baseButton";
import { Homepage } from "./homepage";
import { AboutPage } from "./abouttpage";

import Post01 from "./post01";
import Post02 from "./post02";
import Post03 from "./post03";
import Post04 from "./post04"; import SecondRing01 from "../images/SecondRing01.gif"
import Post05 from "./post05";
import Post06 from "./post06"; import Collection from "../images/Collection.jpg"
import Post07 from "./post07";


import { BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import NotFound from './notFound';
import ScrollToTop from "../components/scrollToTop";

// var //
function DisplayController(props) {

  const location = useLocation();

  const [height, width] = useWindowSize();
  const previous = "post01";
  const [curDisplay, setDisplay] = useState("p");
  const [oldDisplay, setOldDisplay] = useState("hi");

  const pages = ["/","/Rules_of_Robots", "/Exiles_Of_Eternity", "/Lost_Island", "/The_Second_Ring", "/05", "/Collection","/07","/08"]
  const imgSrc = ["https://picsum.photos/100/100", "https://i.ibb.co/Bq3FyFH/Ro-Rbuttonimg.gif", "https://i.ibb.co/wzp47pc/Exiles01.gif", "https://i.ibb.co/vsp5W5g/Pirate-Island-Cover.jpg", SecondRing01, "https://picsum.photos/450/240", Collection, "https://picsum.photos/700/402", "https://picsum.photos/700/403", "https://picsum.photos/700/404", "https://picsum.photos/700/405"]

  const BtnPressed = (cur) => {
    setOldDisplay(curDisplay);
    setDisplay(cur);
  }

  // 404 redirect  //
  const Home = () => <h1>Home</h1>
  const WillMatch = () => <h1>Matched!</h1>

  const NoMatch = ({ location }) => {<div><h1>hello</h1></div>}

  // Content //
  return (

    <div>
      <Helmet>
        
        <meta charSet="utf-8"/>
        
        
        <meta name="description"
        content="Erlend Brodal Game Developer Portfolio"
        />

        {location.pathname.substring(1) === "" && <title> Erlend Brodal </title>}
        {location.pathname.substring(1) !== "" && <title> {location.pathname.substring(1)} </title>}
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
    
      <Routes>
        <Route path="/" element={<Homepage imgSrc={imgSrc} pages = {pages}curPage={curDisplay} changeDisplay={BtnPressed} width={width} ></Homepage>} />
        <Route path="/about" element={<AboutPage changeDisplay={BtnPressed} width={width}></AboutPage>} />

        <Route path={pages[1]} element={<Post01 page={pages} id="1" imgSrc={imgSrc} width={width}></Post01>} />
        <Route path={pages[2]} element={<Post02 page={pages} id="2" imgSrc={imgSrc} width={width}></Post02>} />
        <Route path={pages[3]} element={<Post03 page={pages} id="3" imgSrc={imgSrc} width={width}></Post03>} />
        <Route path={pages[4]} element={<Post04 page={pages} id="4" imgSrc={imgSrc} width={width}></Post04>} />
        <Route path={pages[5]} element={<Post05 page={pages} id="5" imgSrc={imgSrc} width={width}></Post05>} />
        <Route path={pages[6]} element={<Post06 page={pages} id="6" imgSrc={imgSrc} width={width}></Post06>} />
        <Route path={pages[7]} element={<Post07 page={pages} id="7" imgSrc={imgSrc} width={width}></Post07>} />
        <Route exact path="*" element={<Link to="/"><NotFound/></Link>}/>
      </Routes>
    

      {location.pathname !== "/" && width < 1000 && <div className={styles.returnButton}> <Link to="/"> <div className={styles.newReturnButton}> <img src="https://i.ibb.co/3Mm3WHb/Return.png" width="50px" height="50px"></img></div></Link> </div>}

     
      <div className={styles.footerDiv}></div>
    </div>
  );
}

export default DisplayController;

// Get screen size //
function useWindowSize() {
  const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
  useEffect(() => {
    const HandleResize = () => {
      setSize([window.innerHeight, window.innerWidth]);
    };
    window.addEventListener("resize", HandleResize);
    return () => {
      window.removeEventListener("resize", HandleResize);
    };
  }, []);
  return size;
}
