import styles from "./post.module.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Sidebar } from "../components/sidebar";
import baseImage from "../images/BaseImage.jpg";

import { PostParent } from "../components/postParent";
import YoutubeEmbed from "../components/youtube";
import { PostImg } from "../components/postImg";


import LostIsland02 from "../images/LostIsland02.jpg";
import SecondRing02_SBS from "../images/SecondRing02_SBS.gif";
import Website01 from "../images/Website01.jpg"

// Content //
function Post06(props) {
  return (
    <div>
      {props.width >= 1000 && (
        <Sidebar>
          <PostParent
            imgSrc={props.imgSrc}
            page={props.page}
            unreal="true"
           
            unity="true"
            maya="true"
            designer="true"
            painter="true"
          
          ></PostParent>
        </Sidebar>
      )}

      <div className={styles.post}>
        <div className={styles.content} nostyle={{ backgroundColor: "red" }}>
          <div class="row">
            <div class="col-sm-12">
              {" "}
              <div className={styles.hTitle}>
                <h2>
                  {props.page[props.id]
                    .substring(1)
                    .replace("_", " ")
                    .replace("_", " ")}
                </h2>
              </div>
            </div>
            <div class="col-sm-7">
              <div class="row"></div>
            </div>
          </div>
          <br />
          <div className={styles.centerDiv}></div>

          {/* START HERE */}
          <p></p>
        
            Summary of some of the project I have worked on in my free time or during University
      

          {/*TANKBALL*/}
    
        </div>
        <br></br>


        <div className={styles.bar}>
          <h4 style={{ textAlign: "left" }}>Tank Ball</h4>
          <p></p>
          <div className={styles.centerDiv}>
            {" "}
            <YoutubeEmbed
              embedId="WZZq8jTzL-o"
              width="300px"
              height="200px"
            ></YoutubeEmbed>{" "}
          </div>
          <p></p>
          <p>
          First project made at my university in Copenhagen. I was in charge
            of all the visuals in the game
          </p>
        </div>
        <br></br>



        <div className={styles.bar}>
          <h4 style={{ textAlign: "left" }}>Zhang Space 🚀</h4>
          <p></p>
          <div className={styles.centerDiv}>
            {" "}
            <YoutubeEmbed
              embedId="-THi8Rg6yQI"
              width="300px"
              height="200px"
            ></YoutubeEmbed>{" "}
          </div>
          <p></p>
          <p>
            Zhang Space is an online space combat shooter set in a large arena
            where up to six players fight against each other until the last ship
            standing! I was in charge of the 3D modeling, texturing and VFX
            development as well as UI and shaders. I was also programming some
            of the mechanics around the player abilities and the UI
          </p>
        </div>
        <br></br>

        <div className={styles.bar}>
          <h4 style={{ textAlign: "left" }}>Pigshot 🐖</h4>
        <p></p>
          <div className={styles.centerDiv}>
            {" "}
            <YoutubeEmbed
              embedId="c-aarUU1vVM"
              width="300px"
              height="200px"
            ></YoutubeEmbed>{" "}
          </div>
          <p></p>
          <p>
            This game was made during a 48h gamejam where we won the first
            prize. I created the character animation system, VFX and UI
          </p>
        </div>
        <br></br>



        
        <div className={styles.bar}>
          <h4 style={{ textAlign: "left" }}>This Website!</h4>
          <p></p>
          <PostImg src={Website01}></PostImg>
        
          <p></p>
          <p>
            
           This website is created by me using React!
          </p>
        </div>
 




        <br></br>
        <div className={styles.bar}>
          {" "}
          <a href="/"> home </a> - <a href="/about"> contact </a> - © Copyright
          2022 Erlend Brodal - All Rights Reserved{" "}
        </div>
      </div>
    </div>
  );
}

export default Post06;