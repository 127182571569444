import styles from "./post.module.css";

import { Sidebar } from "../components/sidebar";
import { BaseButton } from "../components/baseButton";
import ProgressiveImg from "../components/progressiveImg";
import baseImage from "../images/BaseImage.jpg";
import { Softwares } from "../components/sidebar/softwares";
import { Other } from "../components/sidebar/other";

import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Alert, Breadcrumb, Cart, Card } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/row";
import { Link } from "react-router-dom";

import { PostParent } from "../components/postParent";

// Content //
function Post05(props) {
  return (
    <div>
     {props.width >= 1000 && (
        <Sidebar>
        <PostParent page={props.page}>
        </PostParent>
        </Sidebar>
      )}

      <div className={styles.post}>
        <br />
        <ProgressiveImg src={"https://erlendbrodal.com/assets/images/ror-ingamescreenshot-2-1773x997.jpg"} placeholderSrc={baseImage} width="100%" />

        <div className={styles.head}>
        </div>
          <div class="row">
          <div class="col-sm-5"> <div className={styles.hTitle}><h2>{props.page[props.id].substring(1).replace("_", " ").replace("_", " ")}</h2></div> <br></br></div>
            <div class="col-sm-7"> <div className={styles.hButton}><BaseButton title="website" /><BaseButton title="website" /></div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Post05;
