import React from "react";
import styles from "./baseButton.module.css";

export const BaseButton = ({
  children,
  customText,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  imgUrl = "false",
  title,
  outline ="no",
}) => {
  return (
    <button className={outline === "yes" ? styles.btnOutline : styles.btn}>
      <div  className={styles.container}>
        {children}

     {imgUrl !== "false" && <div className={styles.item}>
          <img
            className={styles.image}
            src={imgUrl}
            alt="Sadly the image failed to load :'("
         
          />
        </div>
}
        <div className={styles.item}>
       
          {title}
          {customText}
        </div>



     
      </div>
    </button>
  );
};
/*

      <img
              className={styles.item}
              src="https://sharpraccoon.com/assets/images/imageonline-co-roundcorner.png"
              alt="cant load atm"
              width="20"
              height="10"
            />



            */
