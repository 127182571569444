import React from "react";
import {useState} from 'react';
import styles from "./pagebutton.module.css";
import ProgressiveImg from "./progressiveImg";
import baseImage from "../images/BaseImage.jpg";

export const PageButton = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  imgUrl = "none",
  title = "",
  smallTitle = "",
  word = "none",
  info = "Info00",
  hoverText ="",

}) => {

  const [isHovering, setIsHovering] = useState(false);
  const ima = {imgUrl};

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  

  {/*Adds only blur to big buttons*/}
  const blur = (cur) => {
    if (hoverText !== "") {
      return styles.imageHover;
    }

    const yo = 2;
    return styles.imageHoverS;
  }

  return (
    <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
    
    <button className={smallTitle === "" ? styles.btn : styles.btnSmall} onClick={onClick} type={type}>  
     
              <div className={styles.overlay}>
              {children}
           
     
                {imgUrl!=="none" && <div className={ isHovering ? blur() : styles.imageNoHover }>
                 
                  
                  <div style={{height: "100%"}}><ProgressiveImg placeholderSrc = {baseImage} className={smallTitle !== "" ? styles.image : styles.imageSmall}  src={imgUrl} alt="cant load atm" width="100%" height="100%"/> </div> </div>}
                <div className={styles.titlebar}> </div>
                <div className={styles.smallTitle}>{smallTitle} </div>
                <h2 className={styles.title}>{title}     </h2>
                <div className={styles.overlayItem}> </div>
                {isHovering && smallTitle === "" && <div className={styles.hoverText}>{hoverText}</div>}  
                
               
            </div>
          </button>
         <div>
      </div>
    </div>
  );
};
