import React from "react";
import styles from "./base.module.css";

import ProgressiveImg from "./progressiveImg";
import baseImage from "../images/BaseImage.jpg";

export const PostImg = (props) => {


  return (
    <div>
    
  

     <a href={props.src} rel="noopener noreferrer">


      <div className={true === true ? styles.normal : styles.fullscreenContainer}>
        <div className={styles.fullscreenImg}>
        <ProgressiveImg src={props.src} placeholderSrc={baseImage} width={props.imgWidth  ?  props.imgWidth : "100%"} />
      
        </div>
      </div>
      </a>
    </div>
  );
};
