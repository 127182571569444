import React from "react";

import { PageButton } from "../pagebutton";
import { Portfolio } from "./portfolio";
import styles from "./sidebar.module.css";

export const Other = (props) => {

  const links = ["https://i.ibb.co/M8T9J2D/321-3211558-unreal-logo-png-for-kids-unreal-engine-icon.png","https://i.ibb.co/MfCd0P7/Unity.webp", "https://i.ibb.co/3zF1St9/Apps-Blender-1.png","https://i.ibb.co/F6qsgL2/MayaIcon.png" ]
  return (

    <div className={styles.other}>
      <h4 NOstyle={{textAlign: "center"}}></h4>
     
      <Portfolio imgSrc={props.imgSrc} page={props.page} curPage={props.curPage} changeDisplay={props.changeDisplay}></Portfolio>
 
    </div>
  );
};
