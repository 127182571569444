import React from "react";
import "./App.css";
import DisplayController from "./windows/displayController";

import { Button, Alert, Breadcrumb, Cart, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import ScrollToTop from "./components/scrollToTop";

function App() {

  return (
    <div className="App">

      <Router>
      <ScrollToTop>
        <DisplayController></DisplayController>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
