import { PageButton } from "../components/pagebutton";
import styles from "./homepage.module.css";
import { AboutSidebar } from "../components/aboutsidebar";
import { Sidebar } from "../components/sidebar";
import { BaseButton } from "../components/baseButton";
import ProgressiveImg from "../components/progressiveImg";

import baseImage from "../images/BaseImage.jpg";

import { Link } from "react-router-dom";

// Content //
export const Homepage = (props) => {

  return (
    <div>


      {props.width >= 1000 && (
        <Sidebar>
          <AboutSidebar changeDisplay={props.changeDisplay} />

        </Sidebar>
      )}


      <div className={styles.container}>
        <div className={styles.verticalCenter}>

          <div className={styles.content} nostyle={{ backgroundColor: "blue" }}>


            <ul className={styles.fix} nostyle={{ backgroundColor: "red" }} >


              {props.width <= 1000 && <li >
                <div className={styles.size} >

                  <div className={styles.aboutBtn}>
                    <div >
                      <div> <div className={styles.aboutBtnContent} >   <h1>Erlend Brodal</h1>     <h4 style={{textAlign:"center"}}>Technical Artist</h4> <br />
                        <div style={{marginRight: "0px"}}><Link to="/about"><BaseButton  title=" About/Contact " onClick={() => { props.changeDisplay("aboutPage"); }}> </BaseButton></Link> </div></div></div>
                    </div>
                  </div>
                </div>
              </li>}


              <li >
                <div className={styles.size} nostyle={{ backgroundColor: "purple" }}> <Link to={props.pages[1]}><PageButton imgUrl={props.imgSrc[1]} title={props.pages[1].substring(1).replace("_", " ").replace("_", " ")} hoverText="Online arena brawler sandbox"></PageButton></Link> </div>
              </li>
              <li>
                <div className={styles.size}> <Link to={props.pages[2]}><PageButton imgUrl={props.imgSrc[2]} title={props.pages[2].substring(1).replace("_", " ").replace("_", " ")} hoverText="Multiplayer PvPvE developed during a student project with a team of two"></PageButton></Link> </div>
              </li>
              <li>
                <div className={styles.size}> <Link to={props.pages[3]}><PageButton imgUrl={props.imgSrc[3]} title={props.pages[3].substring(1).replace("_", " ").replace("_", " ")} hoverText="Personal project I made to learn Unreal Engine 5"></PageButton></Link> </div>
              </li>
              <li>
                <div className={styles.size}> <Link to={props.pages[4]}><PageButton imgUrl={props.imgSrc[4]} title={props.pages[4].substring(1).replace("_", " ").replace("_", " ")} hoverText="Short walking simulator made in a few days as a student project"></PageButton></Link> </div>
              </li>
              <li>
                <div className={styles.size}> <Link to={props.pages[6]}><PageButton imgUrl={props.imgSrc[6]} title={props.pages[6].substring(1).replace("_", " ").replace("_", " ")} hoverText="A Collection of personal and student projects I have worked on"></PageButton></Link> </div>
              </li>
              <li>
                {/*<div className={styles.size}> <div style={{backgroundColor: "rgb(12,12,12)", height: "250px", borderRadius: "20px", padding: "20px"}}>hello <h1>yo</h1> </div></div>*/}
              </li>




              
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}


