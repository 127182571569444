import React from "react";
import styles from "./base.module.css";

export const Sidebar = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize,
    imgUrl = "false",
    title,
  }) => {
    return (
        <div className={styles.sidebarSticky}>{children}

        <div style={{position: "absolute", bottom: "0", textAlign: "center"}}><h2 style={{textAlign: "center", fontSize: "15px", paddingLeft: "100px"}}>erlendbrodal@gmail.com</h2></div>
        </div>
     
    );
  };