import styles from "./post.module.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Sidebar } from "../components/sidebar";
import { BaseButton } from "../components/baseButton";
import ProgressiveImg from "../components/progressiveImg";
import baseImage from "../images/BaseImage.jpg";

import { PostParent } from "../components/postParent";
import YoutubeEmbed from "../components/youtube";
import { PostImg } from "../components/postImg";

import Exilesvillage from "../images/ExilesVillage.jpg"
import ExilesModularKit from "../images/ModularKitExiles.jpg"
import Exiles03 from "../images/Exiles03.jpg"
import Exiles04 from "../images/Exiles04.png"



// Content //
function Post02(props) {
  return (
    <div>
      {props.width >= 1000 && (
        <Sidebar>
          <PostParent
            imgSrc={props.imgSrc}
            page={props.page}
            unreal="true"
            maya="true"
            worldmachine="true"
            painter="true"
            designer="true"
            speedtree="true"
          ></PostParent>
        </Sidebar>
      )}

      <div className={styles.post}>
        <div className={styles.content} nostyle={{ backgroundColor: "red" }}>
     
              <div className={styles.hTitle}>
                <h2 >
                  {props.page[props.id]
                    .substring(1)
                    .replace("_", " ")
                    .replace("_", " ")}
                </h2>
              </div>
        
    

          <br></br>

          {/*youtube*/}
          <div className={styles.centerDiv}>
            {" "}
            <YoutubeEmbed
              embedId="pZ5FfcWeFHE"
              width="300px"
              height="200px"
            ></YoutubeEmbed>{" "}
          </div>
          <p></p>

          <p>
            Exiles of Eternity is a magic themed battle royale RPG placed in a
            magical fantasy world, where you fight using unique spells and
            abilities that you discover throughout the game. Fight monsters,
            level up your character and upgrade your abilities in your quest to
            become the last man standing!
          </p>

          <p>
            {" "}
            My <b>main contribution</b> to this project was developing,
            implementing, and controlling all the visual aspects of the game, as
            the only artist in the team of two. This includes creating the
            character animation controller, environment (Props and terrain),
            shaders, particle effects, sound and UI.
            {/* 
            Creating environments for games was something I did not
            have much experience in before this project, especially not when it
            came to foliage and terrain creation. Developing such a huge map
            that should work with many players, while also being balanced and
            optimized was a big challenge, but a fun one!*/}
          </p>
          <br></br>

          <h4> Animation Controller</h4>
          <div class="row" style={{ padding: "5px", margin: "-5px" }}>
            <div class="col-md-6 p-1">
              {" "}
              <PostImg
                src={"https://i.ibb.co/0Gn5M7g/exiles-anim-Tree.png"}
                placeholderSrc={baseImage}
                width="100%"
              />{" "}
            </div>
            <div class="col-md-5 p-1">
              {" "}
              <PostImg
                src={"https://i.ibb.co/bWFXskf/exiles-anim-BP.jpg"}
                placeholderSrc={baseImage}
                width="100%"
              />{" "}
            </div>
          </div>
          <p></p>

          <p>
            The biggest challenge in this project, and the most fun one, was
            making the animation controller, so it handled, walking, strafing,
            aiming, and attacking, while keeping the transition smooth between
            the animations, networked. The character mesh was from Epic Games
            and came with most of the animation used in the project, however the
            animation controller I created myself. Some animations, such as
            crouching, I created by blending multiple animations together, and
            using skeleton masks to merge various parts of the animations
            together.
          </p>

          <br></br>

          <h4> Terrain </h4>
          <p></p>

          <PostImg
            src={Exiles03}
          ></PostImg>

          <br></br>

          <p>
            I first used World Machine to quickly generate iterations of the
            terrain, and then manually painted the heights on top, when we had a set
            idea of how the map should be. The map was constantly changing after
            getting feedback during game testing, to get the combat as balanced as
            possible.
          </p>
          <p>
            Most of the foliage is placed procedurally and takes slopes,
            distance and terrain material layers into consideration.{" "}
          </p>

          <br></br>

          <h4>Shaders</h4>

          <PostImg
            src={Exiles04}
          ></PostImg>
          <p className="imgText">Part of the terrain shader </p>

          <p>
            I made a custom terrain shader, to procedurally paint the terrain.
            For my grass and snow materials, I blended the textures with rock
            textures based on terrain slopes. By doing this I would not need to
            manually paint over rocks and cliffs in steep areas. I also added
            the option to mask out different materials based on their heights,
            so that I could put rock / snow textures in the higher
            areas of the map, and sand / grass in the lower areas as an example. The shader
            also gave me the option to manually paint textures in areas, which
            is handy for painting details, and give the environment a personal
            touch where it looked "too procedural".
          </p>
          <p className="NOimgText">
            I used a sub-shader for each texture type, so that I could change
            the behavior of each texture type individually. From the main shader
            I also parsed in the distance value to the sub-shaders, so that I
            can tile textures based on camera distance, to get less
            repetitiveness when looking at textures from a distance.
          </p>
          <p>
            The shader I developed for static assets gave the option to adjust
            color, saturation and tiling to adjust textures to fit lighting.
       
            For trees and other foliage the shader adds wind and color tint to
            each individual asset, so every tree would look different
          </p>
          <br></br>

          <h4>Props</h4>

          <PostImg src={ExilesModularKit}></PostImg>

    
          
          <p></p>

          <p>
            To be able to make an entire village for the game I made a modular
            kit, since I wanted some varity to each building. I then built all
            the various buildings straight in the editor giving me a non
            destructive workflow to build with. Once a building was complete I merged it into a single mesh for better optimization.

      
          </p>
          
          <PostImg src={Exilesvillage}></PostImg>
          <p></p>
<p>        
            I also made sure each mesh was UV mapped in a way that I could apply
            any seamless texture to them, giving me the option to change the
            look of any parts of a building even after merging them, by using
            material groups. In addition to that, using Substance Designer gave me even more options to add variety, like changing brick tiles or adding damage and wear.</p>

          {/*Add the schoold paper text about texture atlas and trees*/}
        </div>
        <br></br>
        <div className={styles.bar}>
          {" "}
          <a href="/"> home </a> - <a href="/about"> contact </a> - © Copyright
          2022 Erlend Brodal - All Rights Reserved{" "}
        </div>
      </div>
    </div>
  );
}

export default Post02;
