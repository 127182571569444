import { useState, useEffect } from "react";
import styles from "./progressiveImg.module.css";
import baseImage from "../images/BaseImage.jpg";

const ProgressiveImg = ({ placeholderSrc, src, ...props }) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

  useEffect(() => {
    const img = new Image();
    img.src = src;
   
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);
  return (
    <img 
      {...{ src: imgSrc, ...props }}
      alt={props.alt || ""}
      className={styles.loading}
    />
  );
};
export default ProgressiveImg;
