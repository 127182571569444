import { PageButton } from "../components/pagebutton";
import styles from "./post.module.css";
import misc from "./misc.module.css";
import { AboutSidebar } from "../components/aboutsidebar";
import { Sidebar } from "../components/sidebar";
import { BaseButton } from "../components/baseButton";
import { Button, Alert, Breadcrumb, Cart, Card } from "react-bootstrap";

import { Link } from "react-router-dom";
import { Other } from "../components/sidebar/other";
import { PostParent } from "../components/postParent";

import baseImage from "../images/BaseImage.jpg";
import SoftwaresIcon from "../images/icons/Softwares.png"
import { PostImg } from "../components/postImg";

export const AboutPage = (props) => {
  return (
    <div>
      {props.width >= 1000 && (
        <Sidebar>

 <div style={{paddingTop: "8px", paddingLeft: "10px"}}> <Link to="/"><BaseButton imgUrl="https://i.ibb.co/3Mm3WHb/Return.png" title="return" ></BaseButton> </Link> <hr></hr></div>

 <div style={{padding: "10px"}}>
          <h2>Experience</h2>
        <div style={{padding: "20px" }}>
     
          <h4>Unreal Engine</h4>
          <h4>Unity</h4>
          <h4>C#</h4>
          <h4>VR</h4>
          <h4>Web Development</h4>
          <h4>Procedural Generation</h4>
          <h4>3D moddeling</h4>
          <h4>Shader Development</h4>
          <h4>Animation Controllers</h4>
          <h4>Texturing</h4>
          <h4></h4>


        </div>
      </div>

        
        </Sidebar>
      )}

      <div className={styles.post}>
        <div className={styles.content} nostyle={{ backgroundColor: "red" }}>
       
         <div className={styles.aboutHeader} > <h1 style={{paddingBottom: "10px"}}>Erlend Brodal</h1>  <a href = "mailto: erlendbrodal@gmail.com"><BaseButton  title="erlendbrodal@gmail.com"></BaseButton></a>  </div>
          <h4 style={{paddingBottom: "0px", paddingTop: "10px"}}>About Me</h4>

          
          <div className={misc.aboutMe}>
            {" "}
            <p>
              I am a Technical Artist currently living in Trondheim.
               My experiene in game development comes from my masters in Games, starting an indie company, and working for other companies,
                allowing me to work on many various projects, and team sizes. 
              <br></br>
              <br></br>
              My specialties are shader development, procedural generation, 3D and programming. I have 6+ years of experience with Unreal Engine and Unity, and is familiar with most of their tools and pipeline aswell as creating custom tools for the editor. Working with game development the last years gave me the opportunity to work with many third party software, related to game development, that I can take advantage of.  I mostly work with 3D
              Environments, both big and small, while organizing the whole art
              pipeline from Sketch to Build.
            </p>
          </div>

          {props.width <= 1000 &&  <div className = {styles.list}>
            
            <h4>Experience</h4>
      <ul>
        <li>Unreal Engine</li>
        <li>Unity</li>
        <li>C#</li>
        <li>VR</li>
        <li>Web Development</li>
        <li>Procedural Generation</li>
        <li>3D Moddeling</li>
        <li>Shader Development</li>
        <li>Animation Controllers</li>
        <li>Texturing</li>
        </ul>
                  
  </div>}

    <PostImg src={SoftwaresIcon}></PostImg>


          <hr></hr>


          <div class="cointaier-lg my-5">
            <div class="row justify-content-between">
              <div class="col-md-2">
                <div className={misc.workExperience}>
                  <h2>Work Experience</h2>
                  
                </div>
              </div>
              <div class="col-md-7">
                <div className={misc.workExperienceText}>
                <a href="https://fornixvr.com/">Fornix VR</a> -
                  VR-Developer - 2022 - now <br />

                  <a href="https://sharpraccoon.itch.io/rulesofrobots">Sharp Raccoon</a> -
                  Co-Founder & Technical Artist - 2020 - 2022 <br />
                  <a href="https://way.no/trafikksimulator/">Way AS</a> - Software Developer for Driving Sim - 2019 - 2020 <br />
                  <a href="https://www.nord.no/en">Nord
                  University</a> - Teacher Assistant - 2018-2019
                </div>
              </div>
            </div>
          </div>

          <div class="cointaier-lg my-5">
            <div class="row justify-content-between">
              <div class="col-md-2">
                <div className={misc.workExperience}>
                  <h2>Education</h2>
                </div>
              </div>
              <div class="col-md-7">
                <div className={misc.workExperienceText}>
                  <b>Master </b>of Science in Games - <a href="https://en.itu.dk/programmes/msc-programmes/games">IT University</a> of Copenhagen - 2019 -
                  2021
                  <br />
                  <br></br>
                  <b>Bachelor</b> - Games and Entertainment Technology - <a href="https://www.nord.no/en/studies/bachelor-in-games-and-entertainment-technology">Nord
                  University</a> - 2016 - 2019
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/*

 <div>
            <br></br>
            <br></br>
            <Link to="/">
              {" "}
              <BaseButton
                title="Return"
                onClick={() => {
                  props.changeDisplay("main");
                }}
              ></BaseButton>{" "}
            </Link>

            <h2>Skills</h2>

            <div>
              <div class="row justify-content-between">
                <div class="col">
                  <img
                    src="https://i.ibb.co/M8T9J2D/321-3211558-unreal-logo-png-for-kids-unreal-engine-icon.png"
                    width="70px"
                    height="70px"
                  />
                </div>
                <div class="col">
                  <h3>Unreal Engine</h3>
                </div>
              </div>
              <div class="row justify-content-between">
                <div class="col">
                  <h3>Unreal Engine</h3>
                </div>

                <div class="col">
                  <img
                    src="https://i.ibb.co/M8T9J2D/321-3211558-unreal-logo-png-for-kids-unreal-engine-icon.png"
                    width="70px"
                    height="70px"
                  />
                </div>
              </div>
              <div class="row justify-content-between">
                <div class="col">
                  <h3>Unreal Engine</h3>
                </div>

                <div class="col">
                  <img
                    src="https://i.ibb.co/M8T9J2D/321-3211558-unreal-logo-png-for-kids-unreal-engine-icon.png"
                    width="70px"
                    height="70px"
                  />
                </div>
              </div>
              <div class="row justify-content-between">
                <div class="col">
                  <h3>Unreal Engine</h3>
                </div>

                <div class="col">
                  <img
                    src="https://i.ibb.co/M8T9J2D/321-3211558-unreal-logo-png-for-kids-unreal-engine-icon.png"
                    width="70px"
                    height="70px"
                  />
                </div>
              </div>
              <div class="row justify-content-between">
                <div class="col">
                  <h3>Unreal Engine</h3>
                </div>

                <div class="col">
                  <img
                    src="https://i.ibb.co/M8T9J2D/321-3211558-unreal-logo-png-for-kids-unreal-engine-icon.png"
                    width="70px"
                    height="70px"
                  />
                </div>
              </div>
            </div>
          </div>

*/