import React from "react";
import { PageButton } from "../pagebutton";
import styles from "./sidebar.module.css";
import { Link, useLocation } from "react-router-dom";

export const Portfolio = (props) => {

  const location = useLocation();

  return (

    <div className={styles.portfolio}>

      <div>

        {location.pathname !== props.page[1] && <div className={styles.btn}> <Link to={props.page[1]}>  <PageButton imgUrl={props.imgSrc[1]} smallTitle={props.page[1].substring(1).replace("_", " ").replace("_", " ")} onClick={() => { props.changeDisplay("post01"); }}></PageButton> </Link>  </div>}

        {location.pathname !== props.page[2] && <div className={styles.btn}> <Link to={props.page[2]}>  <PageButton imgUrl={props.imgSrc[2]} smallTitle={props.page[2].substring(1).replace("_", " ").replace("_", " ")} onClick={() => { props.changeDisplay("post01"); }}></PageButton> </Link>  </div>}

        {location.pathname !== props.page[3] && <div className={styles.btn}> <Link to={props.page[3]}>  <PageButton imgUrl={props.imgSrc[3]} smallTitle={props.page[3].substring(1).replace("_", " ").replace("_", " ")} onClick={() => { props.changeDisplay("post01"); }}></PageButton> </Link>  </div>}

        {location.pathname !== props.page[4] && <div className={styles.btn}> <Link to={props.page[4]}>  <PageButton imgUrl={props.imgSrc[4]} smallTitle={props.page[4].substring(1).replace("_", " ").replace("_", " ")} onClick={() => { props.changeDisplay("post01"); }}></PageButton> </Link>  </div>}

        {/*location.pathname !== props.page[5] && <div className={styles.btn}> <Link to={props.page[5]}>  <PageButton imgUrl={props.imgSrc[5]} smallTitle={props.page[5].substring(1).replace("_", " ").replace("_", " ")} onClick={() => { props.changeDisplay("post01"); }}></PageButton> </Link>  </div>*/}

        {location.pathname !== props.page[6] && <div className={styles.btn}> <Link to={props.page[6]}>  <PageButton imgUrl={props.imgSrc[6]} smallTitle={props.page[6].substring(1).replace("_", " ").replace("_", " ")} onClick={() => { props.changeDisplay("post01"); }}></PageButton> </Link>  </div>}

        {/*location.pathname !== props.page[7] && <div className={styles.btn}> <Link to={props.page[7]}>  <PageButton imgUrl={props.imgSrc[7]} smallTitle={props.page[7].substring(1).replace("_", " ").replace("_", " ")} onClick={() => { props.changeDisplay("post01"); }}></PageButton> </Link>  </div>*/}

      </div>
    </div>
  );
};
