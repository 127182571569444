import React from "react";
import Yo from "../../images/icons/yo";
import ProgressiveImg from "../progressiveImg";
import styles from "./sidebar.module.css";

import Speedtree from "../../images/icons/Speedtree.jpg";
import Worldmachine from "../../images/icons/WorldMachine.png";
import Unity from "../../images/icons/Unity.png";


export const Softwares = ({
  unreal,
  unity,
  blender,
  maya,
  painter,
  worldmachine,
  designer,
  PS,
  speedtree,

}) => {

  const links = ["https://i.ibb.co/M8T9J2D/321-3211558-unreal-logo-png-for-kids-unreal-engine-icon.png", Unity, "https://i.ibb.co/3zF1St9/Apps-Blender-1.png","https://i.ibb.co/F6qsgL2/MayaIcon.png", "https://i.ibb.co/M6wQG9h/painter.png", "https://i.ibb.co/56RLbkn/designer.png", Worldmachine ]
  return (

    <div className={styles.s}>
        <h4 NOstyle={{textAlign: "center"}}></h4>
    
        {unreal === "true" && <div class="d-flex justify-content-between">
        <div className={styles.rightElement}> <img className={styles.simage} src={links[0]}/> </div>
        <div className={styles.leftElement}> <h4 className={styles.textOffset}>Unreal Engine</h4></div>
        </div> }

        {unity === "true" && <div class="d-flex justify-content-between">
        <div className={styles.rightElement}> <img className={styles.simage} src={links[1]}/> </div>
        <div className={styles.leftElement}> <h4 className={styles.textOffset}>Unity</h4></div>
        </div> }

        {blender === "true" && <div class="d-flex justify-content-between">
        <div className={styles.rightElement}> <img className={styles.simage} src={links[2]}/> </div>
        <div className={styles.leftElement}> <h4 className={styles.textOffset}>Blender</h4></div>
        </div> }

        {maya === "true" && <div class="d-flex justify-content-between">
        <div className={styles.rightElement}> <img className={styles.simage} src={links[3]}/> </div>
        <div className={styles.leftElement}> <h4 className={styles.textOffset}>Maya</h4></div>
        </div> }

        
        {painter === "true" && <div class="d-flex justify-content-between">
        <div className={styles.rightElement}> <img className={styles.simage} src={links[4]}/> </div>
        <div className={styles.leftElement}> <h4 className={styles.textOffset}>Substance Painter</h4></div>
        </div> }


        {designer === "true" && <div class="d-flex justify-content-between">
        <div className={styles.rightElement}> <img className={styles.simage} src={links[5]}/> </div>
        <div className={styles.leftElement}> <h4 className={styles.textOffset}>Substance Designer</h4></div>
        </div> }

        {worldmachine === "true" && <div class="d-flex justify-content-between">
        <div className={styles.rightElement}> <img className={styles.simage} src={links[6]}/> </div>
        <div className={styles.leftElement}> <h4 className={styles.textOffset}>World Machine</h4></div>
        </div> }

        {PS === "true" && <div class="d-flex justify-content-between">
        <div className={styles.rightElement}> <img className={styles.simage} src={links[7]}/> </div>
        <div className={styles.leftElement}> <h4 className={styles.textOffset}>Photoshop</h4></div>
        </div> }

        {speedtree === "true" && <div class="d-flex justify-content-between">
        <div className={styles.rightElement}> <img className={styles.simage} src={Speedtree}/> </div>
        <div className={styles.leftElement}> <h4 className={styles.textOffset}>Speedtree</h4></div>
        </div> }
    </div>
  );
};
