function Yo () {

    return (
      <div className="App">
        <h1>yoyo</h1>
      </div>
    );
  }
  
  export default Yo;
  